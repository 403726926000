.ProseMirror code {
  caret-color: #ffffff; /* Tailwind 'caret-white' */
  color: #ffffff; /* Tailwind 'text-white' */
  background-color: #171717; /* Tailwind 'bg-neutral-900' */
  border-radius: 0.125rem; /* Tailwind 'rounded-sm' */
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); /* Tailwind 'shadow-lg' */
  font-family: monospace; /* Tailwind 'font-mono' */
}

.ProseMirror code::selection {
  background-color: rgba(255, 255, 255, 0.3); /* Tailwind 'bg-white/30' */
}

.ProseMirror pre {
  caret-color: #ffffff; /* Tailwind 'caret-white' */
  background-color: #404040; /* Tailwind 'bg-neutral-700' */
  color: #ffffff; /* Tailwind 'text-white' */
  border-radius: 0.375rem; /* Tailwind 'rounded' */
  margin-top: 3rem; /* Tailwind 'my-12' */
  margin-bottom: 3rem; /* Tailwind 'my-12' */
  padding: 1rem; /* Tailwind 'p-4' */
  border: 1px solid #000000; /* Tailwind 'border-black' */
}

.dark .ProseMirror pre {
  background-color: #171717; /* Tailwind 'dark:bg-neutral-900' */
  border-color: #2d2d2d; /* Tailwind 'dark:border-neutral-800' */
}

.ProseMirror pre *::selection {
  background-color: rgba(255, 255, 255, 0.2); /* Tailwind 'bg-white/20' */
}

.ProseMirror pre code {
  background-color: inherit; /* Tailwind 'bg-inherit' */
  color: inherit; /* Tailwind 'text-inherit' */
  padding: 0; /* Tailwind 'p-0' */
  box-shadow: none; /* Tailwind 'shadow-none' */
}

/* Highlight.js Specific Styles */
.hljs-comment,
.hljs-quote {
  color: #a3a3a3; /* Tailwind 'text-neutral-400' */
}

.hljs-variable,
.hljs-template-variable,
.hljs-attribute,
.hljs-tag,
.hljs-name,
.hljs-regexp,
.hljs-link,
.hljs-selector-id,
.hljs-selector-class {
  color: #fca5a5; /* Tailwind 'text-red-300' */
}

.hljs-number,
.hljs-meta,
.hljs-built_in,
.hljs-builtin-name,
.hljs-literal,
.hljs-type,
.hljs-params {
  color: #fdba74; /* Tailwind 'text-orange-300' */
}

.hljs-string,
.hljs-symbol,
.hljs-bullet {
  color: #bef264; /* Tailwind 'text-lime-300' */
}

.hljs-title,
.hljs-section {
  color: #fcd34d; /* Tailwind 'text-yellow-300' */
}

.hljs-keyword,
.hljs-selector-tag {
  color: #5eead4; /* Tailwind 'text-teal-300' */
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: 700; /* Tailwind 'font-weight: bold' */
}

/* Tailwind css */

.ProseMirror code {
  @apply caret-white text-white bg-neutral-900 rounded-sm shadow-lg font-mono;
}

.ProseMirror code::selection {
  @apply bg-white/30;
}

.ProseMirror pre {
  @apply caret-white bg-neutral-700 text-white rounded my-12 p-4 border border-black dark:bg-neutral-900 dark:border-neutral-800;
}

.ProseMirror pre *::selection {
  @apply bg-white/20;
}

.ProseMirror pre code {
  @apply bg-inherit text-inherit p-0 shadow-none;
}

.hljs-comment,
.hljs-quote {
  @apply text-neutral-400;
}

.hljs-variable,
.hljs-template-variable,
.hljs-attribute,
.hljs-tag,
.hljs-name,
.hljs-regexp,
.hljs-link,
.hljs-name,
.hljs-selector-id,
.hljs-selector-class {
  @apply text-red-300;
}

.hljs-number,
.hljs-meta,
.hljs-built_in,
.hljs-builtin-name,
.hljs-literal,
.hljs-type,
.hljs-params {
  @apply text-orange-300;
}

.hljs-string,
.hljs-symbol,
.hljs-bullet {
  @apply text-lime-300;
}

.hljs-title,
.hljs-section {
  @apply text-yellow-300;
}

.hljs-keyword,
.hljs-selector-tag {
  @apply text-teal-300;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: 700;
}
