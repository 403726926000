.ProseMirror figure[data-type='imageBlock'] {
  margin: 0; /* Tailwind 'm-0' */
}

.ProseMirror figure[data-type='imageBlock'] img {
  border-radius: 0.375rem; /* Tailwind 'rounded' */
  display: block; /* Tailwind 'block' */
  width: 100%; /* Tailwind 'w-full' */
}

/* Block Quote */
.ProseMirror figure[data-type='blockquoteFigure'] {
  margin-top: 3.5rem; /* Tailwind 'my-14' */
  margin-bottom: 3.5rem; /* Tailwind 'my-14' */
  color: #000000; /* Tailwind 'text-black' */
}

.dark .ProseMirror figure[data-type='blockquoteFigure'] {
  color: #ffffff; /* Tailwind 'dark:text-white' */
}

.ProseMirror > blockquote,
.ProseMirror [data-type='blockquoteFigure'] blockquote {
  margin: 0; /* Tailwind 'm-0' */
}

.ProseMirror > blockquote > *,
.ProseMirror [data-type='blockquoteFigure'] blockquote > * {
  margin-top: 0; /* Tailwind 'first:mt-0' */
  margin-bottom: 0; /* Tailwind 'last:mb-0' */
}

/* Columns */
.ProseMirror [data-type='columns'] {
  display: grid; /* Tailwind 'grid' */
  gap: 1rem; /* Tailwind 'gap-4' */
  margin-top: 3.5rem; /* Tailwind 'mt-14' */
  margin-bottom: 3rem; /* Tailwind 'mb-12' */
}

.ProseMirror [data-type='columns'].layout-sidebar-left {
  grid-template-columns: 40fr 60fr;
}

.ProseMirror [data-type='columns'].layout-sidebar-right {
  grid-template-columns: 60fr 40fr;
}

.ProseMirror [data-type='columns'].layout-two-column {
  grid-template-columns: 1fr 1fr;
}

.ProseMirror [data-type='column'] {
  overflow: auto; /* Tailwind 'overflow-auto' */
}

/* Tailwind css */

.ProseMirror {
  /* Image Block */
  figure[data-type='imageBlock'] {
    @apply m-0;

    img {
      @apply rounded block w-full;
    }
  }

  /* Block Quote */
  figure[data-type='blockquoteFigure'] {
    @apply my-14 text-black dark:text-white;
  }

  & > blockquote,
  [data-type='blockquoteFigure'] {
    blockquote {
      @apply m-0;

      & > * {
        @apply first:mt-0 last:mb-0;
      }
    }
  }

  /* Columns */
  [data-type='columns'] {
    @apply grid gap-4 mt-14 mb-12;
  }

  [data-type='columns'].layout-sidebar-left {
    grid-template-columns: 40fr 60fr;
  }

  [data-type='columns'].layout-sidebar-right {
    grid-template-columns: 60fr 40fr;
  }

  [data-type='columns'].layout-two-column {
    grid-template-columns: 1fr 1fr;
  }

  [data-type='column'] {
    @apply overflow-auto;
  }
}
