.ProseMirror .tableWrapper {
  margin-top: 3rem; /* Tailwind 'my-12' */
  margin-bottom: 3rem; /* Tailwind 'my-12' */
}

.ProseMirror table {
  border-collapse: collapse; /* Tailwind 'border-collapse' */
  border: 1px solid rgba(0, 0, 0, 0.1); /* Tailwind 'border-black/10' */
  border-radius: 0.375rem; /* Tailwind 'rounded' */
  box-sizing: border-box; /* Tailwind 'box-border' */
  width: 100%; /* Tailwind 'w-full' */
}

.dark .ProseMirror table {
  border-color: rgba(255, 255, 255, 0.2); /* Tailwind 'dark:border-white/20' */
}

.ProseMirror td,
.ProseMirror th {
  border: 1px solid rgba(0, 0, 0, 0.1); /* Tailwind 'border-black/10' */
  min-width: 100px; /* Tailwind 'min-w-[100px]' */
  padding: 0.5rem; /* Tailwind 'p-2' */
  position: relative; /* Tailwind 'relative' */
  text-align: left; /* Tailwind 'text-left' */
  vertical-align: top; /* Tailwind 'align-top' */
}

.dark .ProseMirror td,
.dark .ProseMirror th {
  border-color: rgba(255, 255, 255, 0.2); /* Tailwind 'dark:border-white/20' */
}

.ProseMirror td:first-of-type:not(a),
.ProseMirror th:first-of-type:not(a) {
  margin-top: 0; /* Tailwind 'mt-0' */
}

.ProseMirror td p,
.ProseMirror th p {
  margin: 0; /* Tailwind 'm-0' */
}

.ProseMirror td p + p,
.ProseMirror th p + p {
  margin-top: 0.75rem; /* Tailwind 'mt-3' */
}

.ProseMirror th {
  font-weight: bold; /* Tailwind 'font-bold' */
}

.ProseMirror .column-resize-handle {
  bottom: -2px; /* Tailwind '-bottom-[2px]' */
  display: flex; /* Tailwind 'flex' */
  pointer-events: none; /* Tailwind 'pointer-events-none' */
  position: absolute; /* Tailwind 'absolute' */
  right: -1px; /* Tailwind '-right-1' */
  top: 0; /* Tailwind 'top-0' */
  width: 2px; /* Tailwind 'w-2' */
}

.ProseMirror .column-resize-handle::before {
  background-color: rgba(0, 0, 0, 0.2); /* Tailwind 'bg-black/20' */
  height: 100%; /* Tailwind 'h-full' */
  width: 1px; /* Tailwind 'w-[1px]' */
  margin-left: 0.5rem; /* Tailwind 'ml-2' */
  content: ''; /* Tailwind 'content-[""]' */
}

.dark .ProseMirror .column-resize-handle::before {
  background-color: rgba(255, 255, 255, 0.2); /* Tailwind 'dark:bg-white/20' */
}

.ProseMirror .selectedCell {
  background-color: rgba(0, 0, 0, 0.05); /* Tailwind 'bg-black/5' */
  border: 2px double rgba(0, 0, 0, 0.2); /* Tailwind 'border-black/20 border-double' */
}

.dark .ProseMirror .selectedCell {
  background-color: rgba(255, 255, 255, 0.1); /* Tailwind 'dark:bg-white/10' */
  border-color: rgba(255, 255, 255, 0.2); /* Tailwind 'dark:border-white/20' */
}

.ProseMirror .grip-column,
.ProseMirror .grip-row {
  align-items: center; /* Tailwind 'items-center' */
  background-color: rgba(0, 0, 0, 0.05); /* Tailwind 'bg-black/5' */
  cursor: pointer; /* Tailwind 'cursor-pointer' */
  display: flex; /* Tailwind 'flex' */
  justify-content: center; /* Tailwind 'justify-center' */
  position: absolute; /* Tailwind 'absolute' */
  z-index: 10; /* Tailwind 'z-10' */
}

.dark .ProseMirror .grip-column,
.dark .ProseMirror .grip-row {
  background-color: rgba(255, 255, 255, 0.1); /* Tailwind 'dark:bg-white/10' */
}

.ProseMirror .grip-column {
  width: calc(100% + 1px); /* Tailwind 'w-[calc(100%+1px)]' */
  border-left: 1px solid rgba(0, 0, 0, 0.2); /* Tailwind 'border-l border-black/20' */
  height: 0.75rem; /* Tailwind 'h-3' */
  left: 0;
  margin-left: -1px;
  top: -0.75rem;
}

.dark .ProseMirror .grip-column {
  border-left: 1px solid rgba(255, 255, 255, 0.2); /* Tailwind 'dark:border-white/20' */
}

.ProseMirror .grip-column:hover,
.ProseMirror .grip-column.selected {
  background-color: rgba(0, 0, 0, 0.1); /* Tailwind 'bg-black/10' */
}

.dark .ProseMirror .grip-column:hover,
.dark .ProseMirror .grip-column.selected {
  background-color: rgba(255, 255, 255, 0.2); /* Tailwind 'dark:bg-white/20' */
}

.ProseMirror .grip-column:hover::before,
.ProseMirror .grip-column.selected::before {
  width: 0.625rem; /* Tailwind 'h-2.5' */
  content: ''; /* Tailwind 'content-[""]' */
}

.ProseMirror .grip-column:hover::before {
  border-bottom: 2px dotted rgba(0, 0, 0, 0.6); /* Tailwind 'border-b-2 border-dotted border-black/60' */
}

.dark .ProseMirror .grip-column:hover::before {
  border-bottom: 2px dotted rgba(255, 255, 255, 0.6); /* Tailwind 'dark:border-white/60' */
}

.ProseMirror .grip-column.first {
  border-color: transparent; /* Tailwind 'border-transparent' */
  border-top-left-radius: 0.125rem; /* Tailwind 'rounded-tl-sm' */
}

.ProseMirror .grip-column.last {
  border-top-right-radius: 0.125rem; /* Tailwind 'rounded-tr-sm' */
}

.ProseMirror .grip-column.selected {
  background-color: rgba(0, 0, 0, 0.3); /* Tailwind 'bg-black/30' */
  border-left: 1px solid rgba(0, 0, 0, 0.3); /* Tailwind 'border-black/30' */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Tailwind 'shadow-sm' */
}

.dark .ProseMirror .grip-column.selected {
  background-color: rgba(255, 255, 255, 0.3); /* Tailwind 'dark:bg-white/30' */
  border-left: 1px solid rgba(255, 255, 255, 0.3); /* Tailwind 'dark:border-white/30' */
}

.ProseMirror .grip-column.selected::before {
  border-bottom: 2px dotted; /* Tailwind 'border-b-2 border-dotted' */
}

.ProseMirror .grip-row {
  height: calc(100% + 1px); /* Tailwind 'h-[calc(100%+1px)]' */
  border-top: 1px solid rgba(0, 0, 0, 0.2); /* Tailwind 'border-t border-black/20' */
  left: -0.75rem; /* Tailwind '-left-3' */
  width: 0.75rem; /* Tailwind 'w-3' */
  top: 0; /* Tailwind 'top-0' */
  margin-top: -1px; /* Tailwind '-mt-[1px]' */
}

.dark .ProseMirror .grip-row {
  border-top-color: rgba(255, 255, 255, 0.2); /* Tailwind 'dark:border-white/20' */
}

.ProseMirror .grip-row:hover::before,
.ProseMirror .grip-row.selected::before {
  height: 0.625rem; /* Tailwind 'h-2.5' */
  content: ''; /* Tailwind 'content-[""]' */
}

.dark .ProseMirror .grip-row:hover,
.dark .ProseMirror .grip-row.selected {
  background-color: rgba(255, 255, 255, 0.2); /* Tailwind 'dark:bg-white/20' */
}

.ProseMirror .grip-row:hover::before {
  border-left: 2px dotted rgba(0, 0, 0, 0.6); /* Tailwind 'border-l-2 border-dotted border-black/60' */
}

.dark .ProseMirror .grip-row:hover::before {
  border-left: 2px dotted rgba(255, 255, 255, 0.6); /* Tailwind 'dark:border-white/60' */
}

.ProseMirror .grip-row.first {
  border-color: transparent; /* Tailwind 'border-transparent' */
  border-top-left-radius: 0.125rem; /* Tailwind 'rounded-tl-sm' */
}

.ProseMirror .grip-row.last {
  border-bottom-left-radius: 0.125rem; /* Tailwind 'rounded-bl-sm' */
}

.ProseMirror .grip-row.selected {
  background-color: rgba(0, 0, 0, 0.3); /* Tailwind 'bg-black/30' */
  border-top: 1px solid rgba(0, 0, 0, 0.3); /* Tailwind 'border-black/30' */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Tailwind 'shadow-sm' */
}

.dark .ProseMirror .grip-row.selected {
  background-color: rgba(255, 255, 255, 0.3); /* Tailwind 'dark:bg-white/30' */
  border-top: 1px solid rgba(255, 255, 255, 0.3); /* Tailwind 'dark:border-white/30' */
}

.ProseMirror .grip-row.selected::before {
  border-left: 2px dotted; /* Tailwind 'border-l-2 border-dotted' */
}

/* tailwind css */

.ProseMirror {
  .tableWrapper {
    @apply my-12;
  }

  table {
    @apply border-collapse border-black/10 rounded box-border w-full;
    @apply dark:border-white/20;

    td,
    th {
      @apply border border-black/10 min-w-[100px] p-2 relative text-left align-top;
      @apply dark:border-white/20;

      &:first-of-type:not(a) {
        @apply mt-0;
      }

      p {
        @apply m-0;

        & + p {
          @apply mt-3;
        }
      }
    }

    th {
      @apply font-bold;
    }

    .column-resize-handle {
      @apply -bottom-[2px] flex pointer-events-none absolute -right-1 top-0 w-2;

      &::before {
        @apply bg-black/20 h-full w-[1px] ml-2;
        @apply dark:bg-white/20;
        content: '';
      }
    }

    .selectedCell {
      @apply bg-black/5 border-black/20 border-double;
      @apply dark:bg-white/10 dark:border-white/20;
    }

    .grip-column,
    .grip-row {
      @apply items-center bg-black/5 cursor-pointer flex justify-center absolute z-10;
      @apply dark:bg-white/10;
    }

    .grip-column {
      @apply w-[calc(100%+1px)] border-l border-black/20 h-3 left-0 -ml-[1px] -top-3;
      @apply dark:border-white/20;

      &:hover,
      &.selected {
        &::before {
          content: '';
          @apply w-2.5;
        }
      }

      &:hover {
        @apply bg-black/10;
        @apply dark:bg-white/20;

        &::before {
          @apply border-b-2 border-dotted border-black/60;
          @apply dark:border-white/60;
        }
      }

      &.first {
        @apply border-transparent rounded-tl-sm;
      }

      &.last {
        @apply rounded-tr-sm;
      }

      &.selected {
        @apply bg-black/30 border-black/30 shadow-sm;
        @apply dark:bg-white/30 dark:border-white/30;

        &::before {
          @apply border-b-2 border-dotted;
        }
      }
    }

    .grip-row {
      @apply h-[calc(100%+1px)] border-t border-black/20 -left-3 w-3 top-0 -mt-[1px];
      @apply dark:border-white/20;

      &:hover,
      &.selected {
        &::before {
          @apply h-2.5;
          content: '';
        }
      }
      &:hover {
        @apply bg-black/10;
        @apply dark:bg-white/20;

        &::before {
          @apply border-l-2 border-dotted border-black/60;
          @apply dark:border-white/60;
        }
      }

      &.first {
        @apply border-transparent rounded-tl-sm;
      }

      &.last {
        @apply rounded-bl-sm;
      }

      &.selected {
        @apply bg-black/30 border-black/30 shadow-sm;
        @apply dark:bg-white/30 dark:border-white/30;

        &::before {
          @apply border-l-2 border-dotted;
        }
      }
    }
  }
}
