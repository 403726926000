.ProseMirror p {
  line-height: 1.625; /* Tailwind 'leading-relaxed' */
  margin-top: 0.75rem; /* Tailwind 'my-3' */
  margin-bottom: 0.75rem; /* Tailwind 'my-3' */
}

.ProseMirror p:first-child {
  margin-top: 0; /* Tailwind 'first:mt-0' */
}

.ProseMirror p:last-child {
  margin-bottom: 0; /* Tailwind 'last:mb-0' */
}

.ProseMirror > p {
  margin-top: 0; /* Tailwind 'my-0' */
  margin-bottom: 0; /* Tailwind 'my-0' */
}

.ProseMirror h1 {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.ProseMirror h2 {
  font-size: 1.5rem;
  line-height: 2rem;
}

.ProseMirror h3 {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.ProseMirror h4 {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.ProseMirror h5 {
  font-size: 1rem;
  line-height: 1.5rem;
}

.ProseMirror h6 {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
  font-weight: bold; /* Tailwind 'font-bold' */
  margin-top: 0; /* Tailwind 'first:mt-0' */
  margin-bottom: 0; /* Tailwind 'last:mb-0' */
}

.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3 {
  margin-top: 3rem; /* Tailwind 'mt-12' */
}

.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
  margin-top: 2rem; /* Tailwind 'mt-8' */
}

.ProseMirror a.link {
  color: #3b82f6; /* Tailwind 'text-blue-500' */
  font-weight: 800; /* Tailwind 'font-extrabold' */
}

.dark .ProseMirror a.link {
  color: #60a5fa; /* Tailwind 'dark:text-blue-400' */
}

.ProseMirror mark {
  background-color: #ef4444; /* Tailwind 'bg-red-500' */
  border-radius: 0.125rem; /* Tailwind 'rounded-sm' */
  text-decoration: inherit; /* Tailwind 'decoration-clone' */
  color: inherit; /* Tailwind 'text-inherit' */
  padding-top: 0.25rem; /* Tailwind 'py-1' */
  padding-bottom: 0.25rem; /* Tailwind 'py-1' */
  padding-left: 0; /* Tailwind 'px-0' */
  padding-right: 0; /* Tailwind 'px-0' */
}

.dark .ProseMirror mark {
  background-color: #f87171; /* Tailwind 'dark:bg-red-400' */
}

.ProseMirror img {
  height: auto; /* Tailwind 'h-auto' */
  width: 100%; /* Tailwind 'w-full' */
  max-width: 100%; /* Tailwind 'max-w-full' */
}

.ProseMirror [data-type='horizontalRule'] {
  margin-top: 2rem; /* Tailwind 'my-8' */
  margin-bottom: 2rem; /* Tailwind 'my-8' */
  padding-top: 1rem; /* Tailwind 'py-4' */
  padding-bottom: 1rem; /* Tailwind 'py-4' */
  transition: all 0.1s ease-in-out; /* Tailwind 'transition-all duration-100 ease-in-out' */
  cursor: pointer; /* Tailwind 'cursor-pointer' */
}

.ProseMirror [data-type='horizontalRule'].ProseMirror-selectednode {
  background-color: rgba(0, 0, 0, 0.05); /* Tailwind 'bg-black/5' */
}

.dark .ProseMirror [data-type='horizontalRule'].ProseMirror-selectednode {
  background-color: rgba(255, 255, 255, 0.1); /* Tailwind 'dark:bg-white/10' */
}

.ProseMirror [data-type='horizontalRule'].ProseMirror-selectednode hr {
  border-top-color: rgba(0, 0, 0, 0.3); /* Tailwind 'border-t-black/30' */
}

.dark .ProseMirror [data-type='horizontalRule'].ProseMirror-selectednode hr {
  border-top-color: rgba(255, 255, 255, 0.3); /* Tailwind 'dark:border-t-white/30' */
}

.ProseMirror [data-type='horizontalRule']:hover:not(.ProseMirror-selectednode) {
  background-color: rgba(0, 0, 0, 0.05); /* Tailwind 'bg-black/5' */
}

.dark .ProseMirror [data-type='horizontalRule']:hover:not(.ProseMirror-selectednode) {
  background-color: rgba(255, 255, 255, 0.1); /* Tailwind 'dark:bg-white/10' */
}

.ProseMirror [data-type='horizontalRule'] hr {
  border: none; /* Tailwind 'border-0' */
  border-top: 1px solid rgba(0, 0, 0, 0.2); /* Tailwind 'border-t border-black/20' */
  background-color: rgba(0, 0, 0, 0.8); /* Tailwind 'bg-black/80' */
}

.dark .ProseMirror [data-type='horizontalRule'] hr {
  border-top: 1px solid rgba(255, 255, 255, 0.2); /* Tailwind 'dark:border-white/20' */
  background-color: rgba(255, 255, 255, 0.8); /* Tailwind 'dark:bg-white/80' */
}

/* tailwind css */

.ProseMirror {
  p {
    @apply leading-relaxed my-3 first:mt-0 last:mb-0;
  }

  & > p {
    @apply my-0 first:mt-0 last:mb-0;
  }

  h1 {
    @apply text-3xl;
  }

  h2 {
    @apply text-2xl;
  }

  h3 {
    @apply text-xl;
  }

  h4 {
    @apply text-lg;
  }

  h5 {
    @apply text-base;
  }

  h6 {
    @apply text-sm;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-bold first:mt-0 last:mb-0;
  }

  h1,
  h2,
  h3 {
    @apply mt-12;
  }

  h4,
  h5,
  h6 {
    @apply mt-8;
  }

  a.link {
    @apply text-blue-500 font-extrabold dark:text-blue-400;
  }

  mark {
    @apply bg-red-500 rounded-sm decoration-clone text-inherit py-1 px-0 dark:bg-red-400;
  }

  & img {
    @apply h-auto w-full max-w-full;
  }

  [data-type='horizontalRule'] {
    @apply my-8 py-4 transition-all duration-100 ease-in-out cursor-pointer;

    &.ProseMirror-selectednode {
      @apply bg-black/5 dark:bg-white/10;

      hr {
        @apply border-t-black/30 dark:border-t-white/30;
      }
    }

    &:hover:not(&.ProseMirror-selectednode) {
      @apply bg-black/5;
      @apply dark:bg-white/10;
    }

    hr {
      @apply border-0 border-t border-black/20 bg-black/80;
      @apply dark:border-white/20 dark:bg-white/80;
    }
  }
}
