.ProseMirror ol {
  list-style-type: decimal; /* Tailwind 'list-decimal' */
}

.ProseMirror ul {
  list-style-type: disc; /* Tailwind 'list-disc' */
}

.ProseMirror ul,
.ProseMirror ol {
  padding-top: 0; /* Tailwind 'py-0' */
  padding-left: 1.25rem; /* Tailwind 'pl-5' */
  padding-right: 0; /* Tailwind 'pr-0' */
  margin-top: 1.5rem; /* Tailwind 'my-6' */
  margin-bottom: 1.5rem; /* Tailwind 'my-6' */
}

.ProseMirror ul:first-child,
.ProseMirror ol:first-child {
  margin-top: 0; /* Tailwind 'first:mt-0' */
}

.ProseMirror ul:last-child,
.ProseMirror ol:last-child {
  margin-bottom: 0; /* Tailwind 'last:mb-0' */
}

.ProseMirror ul ul,
.ProseMirror ul ol,
.ProseMirror ul li,
.ProseMirror ol ul,
.ProseMirror ol ol,
.ProseMirror ol li {
  margin-top: 0.25rem; /* Tailwind 'my-1' */
  margin-bottom: 0.25rem; /* Tailwind 'my-1' */
}

.ProseMirror ul p,
.ProseMirror ol p {
  margin-top: 0; /* Tailwind 'mt-0' */
  margin-bottom: 0.25rem; /* Tailwind 'mb-1' */
}

.ProseMirror > ul,
.ProseMirror > ol {
  margin-top: 2rem; /* Tailwind 'my-8' */
  margin-bottom: 2rem; /* Tailwind 'my-8' */
}

.ProseMirror > ul:first-child,
.ProseMirror > ol:first-child {
  margin-top: 0; /* Tailwind 'first:mt-0' */
}

.ProseMirror > ul:last-child,
.ProseMirror > ol:last-child {
  margin-bottom: 0; /* Tailwind 'last:mb-0' */
}

.ProseMirror ul[data-type='taskList'] {
  list-style-type: none; /* Tailwind 'list-none' */
  padding: 0; /* Tailwind 'p-0' */
}

.ProseMirror ul[data-type='taskList'] p {
  margin: 0; /* Tailwind 'm-0' */
}

.ProseMirror ul[data-type='taskList'] li {
  display: flex; /* Tailwind 'flex' */
}

.ProseMirror ul[data-type='taskList'] li > label {
  flex-grow: 0; /* Tailwind 'grow-0' */
  flex-shrink: 0; /* Tailwind 'shrink-0' */
  flex-basis: auto; /* Tailwind 'flex-auto' */
  margin-top: 0.25rem; /* Tailwind 'mt-1' */
  margin-right: 0.5rem; /* Tailwind 'mr-2' */
  user-select: none; /* Tailwind 'select-none' */
}

.ProseMirror ul[data-type='taskList'] li > div {
  flex: 1 1 auto; /* Tailwind 'flex-auto' */
}

.ProseMirror ul[data-type='taskList'] li[data-checked='true'] {
  /* Add custom styles here if needed */
}

/* Tailwind css */

.ProseMirror {
  ol {
    @apply list-decimal;
  }

  ul {
    @apply list-disc;
  }

  ul,
  ol {
    @apply py-0 pl-5 pr-0 my-6 first:mt-0 last:mb-0;

    ul,
    ol,
    li {
      @apply my-1;
    }

    p {
      @apply mt-0 mb-1;
    }
  }

  & > ul,
  & > ol {
    @apply my-8 first:mt-0 last:mb-0;
  }

  ul[data-type='taskList'] {
    @apply list-none p-0;
  }

  ul[data-type='taskList'] p {
    @apply m-0;
  }

  ul[data-type='taskList'] li {
    @apply flex;
  }

  ul[data-type='taskList'] li > label {
    @apply grow-0 shrink-0 flex-auto mt-1 mr-2 select-none;
  }

  ul[data-type='taskList'] li > div {
    @apply flex-auto;
  }

  ul[data-type='taskList'] li[data-checked='true'] {
  }
}
