.ProseMirror .is-empty::before {
  color: #737373; /* Tailwind 'text-neutral-500' */
}

.dark .ProseMirror .is-empty::before {
  color: #a3a3a3; /* Tailwind 'dark:text-neutral-400' */
}

.ProseMirror > *:first-child,
.ProseMirror [data-type='column'] > * {
  margin-top: 0; /* Tailwind 'first:mt-0' */
  margin-bottom: 0; /* Tailwind 'last:mb-0' */
}

.ProseMirror > * + * {
  margin-top: 0.75em;
}

.ProseMirror .node-imageUpload {
  border-radius: 0.375rem; /* Tailwind 'rounded' */
  border: 2px dotted rgba(0, 0, 0, 0.1); /* Tailwind 'border-2 border-dotted border-black border-opacity-10' */
  padding: 0.5rem; /* Tailwind 'p-2' */
  transition: border 160ms cubic-bezier(0.45, 0.05, 0.55, 0.95);
}

.dark .ProseMirror .node-imageUpload {
  border-color: #6b7280; /* Tailwind 'dark:border-neutral-500' */
}

.ProseMirror .node-imageUpload:hover {
  border-opacity: 0.3; /* Tailwind 'border-opacity-30' */
}

.ProseMirror .node-imageUpload.is-active,
.ProseMirror .node-imageUpload.has-focus {
  border-opacity: 0.4; /* Tailwind 'border-opacity-40' */
}

.ProseMirror [data-type='columns'].has-focus [data-type='column'],
.ProseMirror [data-type='columns']:hover [data-type='column'] {
  border-color: #d1d5db; /* Tailwind 'border-neutral-300' */
}

.dark .ProseMirror [data-type='columns'].has-focus [data-type='column'],
.dark .ProseMirror [data-type='columns']:hover [data-type='column'] {
  border-color: #374151; /* Tailwind 'dark:border-neutral-700' */
}

.ProseMirror [data-type='column'].has-focus {
  border-color: #9ca3af; /* Tailwind 'border-neutral-400' */
}

.dark .ProseMirror [data-type='column'].has-focus {
  border-color: #4b5563; /* Tailwind 'dark:border-neutral-600' */
}

.ProseMirror [data-type='column'] {
  border-radius: 0.375rem; /* Tailwind 'rounded' */
  border: 2px dotted transparent; /* Tailwind 'border-2 border-dotted border-transparent' */
  padding: 0.25rem; /* Tailwind 'p-1' */
  transition: border 160ms cubic-bezier(0.45, 0.05, 0.55, 0.95);
}

.ProseMirror [data-type='column']:hover {
  border-color: #f3f4f6; /* Tailwind 'border-neutral-100' */
}

.dark .ProseMirror [data-type='column']:hover {
  border-color: #111827; /* Tailwind 'dark:border-neutral-900' */
}

.ProseMirror [data-type='column'].is-active,
.ProseMirror [data-type='column'].has-focus {
  border-color: #f3f4f6; /* Tailwind 'border-neutral-100' */
}

.dark .ProseMirror [data-type='column'].is-active,
.dark .ProseMirror [data-type='column'].has-focus {
  border-color: #111827; /* Tailwind 'dark:border-neutral-900' */
}

.ProseMirror .node-imageBlock img {
  border: 2px solid transparent; /* Tailwind 'border-2 border-transparent' */
  border-radius: 0.75rem; /* Tailwind 'rounded-xl' */
  overflow: hidden;
}

.ProseMirror .node-imageBlock:hover img {
  border-color: #f3f4f6; /* Tailwind 'border-2 border-neutral-100' */
}

.dark .ProseMirror .node-imageBlock:hover img {
  border-color: #111827; /* Tailwind 'dark:border-neutral-900' */
}

.ProseMirror .node-imageBlock.is-active img,
.ProseMirror .node-imageBlock.has-focus img {
  border-color: #1f2937; /* Tailwind 'border-2 border-neutral-800' */
}

.dark .ProseMirror .node-imageBlock.is-active img,
.dark .ProseMirror .node-imageBlock.has-focus img {
  border-color: #e5e7eb; /* Tailwind 'dark:border-neutral-200' */
}

.ProseMirror .ProseMirror-gapcursor + .node-imageBlock,
.ProseMirror .ProseMirror-gapcursor + .node-imageUpload,
.ProseMirror .ProseMirror-gapcursor + [data-type='blockquoteFigure'] {
  outline-color: #374151; /* Tailwind 'outline-neutral-700' */
}

.ProseMirror .ProseMirror-gapcursor + .node-imageBlock:hover,
.ProseMirror .ProseMirror-gapcursor + .node-imageUpload:hover,
.ProseMirror .ProseMirror-gapcursor + [data-type='blockquoteFigure']:hover {
  outline-color: #4b5563; /* Tailwind 'hover:outline-neutral-600' */
}

.dark .ProseMirror .ProseMirror-gapcursor + .node-imageBlock,
.dark .ProseMirror .ProseMirror-gapcursor + .node-imageUpload,
.dark .ProseMirror .ProseMirror-gapcursor + [data-type='blockquoteFigure'] {
  outline-color: #d1d5db; /* Tailwind 'dark:outline-neutral-300' */
}

.dark .ProseMirror .ProseMirror-gapcursor + .node-imageBlock:hover,
.dark .ProseMirror .ProseMirror-gapcursor + .node-imageUpload:hover,
.dark .ProseMirror .ProseMirror-gapcursor + [data-type='blockquoteFigure']:hover {
  outline-color: #9ca3af; /* Tailwind 'dark:hover:outline-neutral-400' */
}

.ProseMirror [data-type='blockquoteFigure'] > div {
  border-left: 4px solid #374151; /* Tailwind 'border-l-4 border-l-neutral-700' */
  padding: 0.5rem 1rem; /* Tailwind 'py-2 px-4' */
  background-opacity: 0.8; /* Tailwind 'bg-opacity-80' */
  border-radius: 0.375rem; /* Tailwind 'rounded-lg' */
  border-top-left-radius: 0; /* Tailwind 'rounded-tl-none' */
  border-bottom-left-radius: 0; /* Tailwind 'rounded-bl-none' */
}

.dark .ProseMirror [data-type='blockquoteFigure'] > div {
  border-left-color: #d1d5db; /* Tailwind 'dark:border-l-neutral-300' */
}

.ProseMirror [data-type='blockquoteFigure'] > blockquote {
  font-size: 1.125rem; /* Tailwind 'text-lg' */
  color: #000000; /* Tailwind 'text-black' */
}

.dark .ProseMirror [data-type='blockquoteFigure'] > blockquote {
  color: #ffffff; /* Tailwind 'dark:text-white' */
}

.ProseMirror [data-type='blockquoteFigure'] figcaption {
  font-size: 0.875rem; /* Tailwind 'text-sm' */
  color: #737373; /* Tailwind 'text-neutral-500' */
  margin-top: 1rem; /* Tailwind 'mt-4' */
  transition: all 0.2s ease-in-out; /* Tailwind 'transition-all' */
  overflow: hidden;
}

.dark .ProseMirror [data-type='blockquoteFigure'] figcaption {
  color: #a3a3a3; /* Tailwind 'dark:text-neutral-400' */
}

.ProseMirror {
  .is-empty::before {
    @apply text-neutral-500 dark:text-neutral-400;
  }

  & > *:first-child,
  [data-type='column'] > * {
    @apply first:mt-0 last:mb-0;
  }

  & > * + * {
    margin-top: 0.75em;
  }

  .node-imageUpload {
    @apply rounded border-2 border-dotted border-black border-opacity-10 p-2 dark:border-neutral-500;
    transition: border 160ms cubic-bezier(0.45, 0.05, 0.55, 0.95);

    &:hover {
      @apply border-opacity-30;
    }

    &:has(.is-active),
    &.has-focus {
      @apply border-opacity-40;
    }
  }

  [data-type='columns'] {
    &.has-focus [data-type='column'],
    &:hover [data-type='column'] {
      @apply border-neutral-300 dark:border-neutral-700;
    }

    [data-type='column'].has-focus {
      @apply border-neutral-400 dark:border-neutral-600;
    }
  }

  [data-type='column'] {
    @apply rounded border-2 border-dotted border-transparent p-1;
    transition: border 160ms cubic-bezier(0.45, 0.05, 0.55, 0.95);

    &:hover {
      @apply border-neutral-100 dark:border-neutral-900;
    }

    &:has(.is-active),
    &.has-focus {
      @apply border-neutral-100 dark:border-neutral-900;
    }
  }

  .node-imageBlock {
    & img {
      @apply border-2 border-transparent rounded-xl overflow-hidden;
    }

    &:hover img {
      @apply border-2 border-neutral-100 dark:border-neutral-900;
    }

    &:has(.is-active) img,
    &.has-focus img {
      @apply border-2 border-neutral-800 dark:border-neutral-200;
    }
  }

  .ProseMirror-gapcursor + .node-imageBlock,
  .ProseMirror-gapcursor + .node-imageUpload,
  .ProseMirror-gapcursor + [data-type='blockquoteFigure'] {
    @apply outline-neutral-700 hover:outline-neutral-600 dark:outline-neutral-300 dark:hover:outline-neutral-400;
  }

  [data-type='blockquoteFigure'] > div {
    @apply border-l-4 border-l-neutral-700 py-2 px-4 bg-opacity-80 rounded-lg rounded-tl-none rounded-bl-none;
    @apply dark:border-l-neutral-300;
  }

  [data-type='blockquoteFigure'] > blockquote {
    @apply text-lg text-black;
    @apply dark:text-white;
  }

  [data-type='blockquoteFigure'] figcaption {
    @apply text-sm text-neutral-500 mt-4 transition-all overflow-hidden;
    @apply dark:text-neutral-400;
  }
}
