.spinner-animation {
  animation: spinner 1.6s linear infinite;
  animation-delay: -1.6s;
}

.spinner-animation-secondary {
  animation-delay: -1s;
}

@keyframes spinner {
  12.5% {
    x: 13px;
    y: 1px;
  }
  25% {
    x: 13px;
    y: 1px;
  }
  37.5% {
    x: 13px;
    y: 13px;
  }
  50% {
    x: 13px;
    y: 13px;
  }
  62.5% {
    x: 1px;
    y: 13px;
  }
  75% {
    x: 1px;
    y: 13px;
  }
  87.5% {
    x: 1px;
    y: 1px;
  }
}
