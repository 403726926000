.ProseMirror {
  caret-color: #000000; /* Tailwind 'caret-black' */
  caret-color: #ffffff; /* Tailwind 'dark:caret-white' */
  outline: 0; /* Tailwind 'outline-0' */
  padding-right: 2rem; /* Tailwind 'pr-8' */
  padding-left: 6rem; /* Tailwind 'pl-24' */
  padding-top: 4rem; /* Tailwind 'py-16' */
  padding-bottom: 4rem; /* Tailwind 'py-16' */
  z-index: 0; /* Tailwind 'z-0' */
}

.ProseMirror > * {
  margin-left: auto; /* Tailwind 'mx-auto' */
  margin-right: auto; /* Tailwind 'mx-auto' */
}

.ProseMirror .selection {
  display: inline; /* Tailwind 'inline' */
}

.ProseMirror .selection,
.ProseMirror *::selection {
  background-color: rgba(0, 0, 0, 0.1); /* Tailwind 'bg-black/10' */
  background-color: rgba(255, 255, 255, 0.2); /* Tailwind 'dark:bg-white/20' */
}

.ProseMirror > .react-renderer {
  margin-top: 3rem; /* Tailwind 'my-12' */
  margin-bottom: 3rem; /* Tailwind 'my-12' */
}

.ProseMirror > .react-renderer:first-child {
  margin-top: 0; /* Tailwind 'first:mt-0' */
}

.ProseMirror > .react-renderer:last-child {
  margin-bottom: 0; /* Tailwind 'last:mb-0' */
}

.ProseMirror.resize-cursor {
  cursor: col-resize; /* Tailwind 'cursor-col-resize' */
}

.ProseMirror .ProseMirror-gapcursor {
  position: relative; /* Tailwind 'relative' */
  width: 100%; /* Tailwind 'w-full' */
  max-width: 42rem; /* Tailwind 'max-w-2xl' */
  margin-left: auto; /* Tailwind 'mx-auto' */
  margin-right: auto; /* Tailwind 'mx-auto' */
}

.ProseMirror .ProseMirror-gapcursor:after {
  border-top-color: rgba(0, 0, 0, 0.4); /* Tailwind 'border-t-black/40' */
  border-top-color: rgba(255, 255, 255, 0.4); /* Tailwind 'dark:border-t-white/40' */
  width: 100%; /* Tailwind 'w-full' */
  top: -1.5em; /* Tailwind '-top-[1.5em]' */
  max-width: 42rem; /* Tailwind 'max-w-2xl' */
  margin-left: auto; /* Tailwind 'mx-auto' */
  margin-right: auto; /* Tailwind 'mx-auto' */
  left: 0;
  right: 0;
  content: ''; /* 필수: content 속성이 비어 있지 않도록 설정 */
}

/* tailwind css */
.ProseMirror {
  @apply caret-black dark:caret-white outline-0 pr-8 pl-24 py-16 z-0;

  & > * {
    @apply mx-auto;
  }

  .selection {
    @apply inline;
  }

  .selection,
  *::selection {
    @apply bg-black/10 dark:bg-white/20;
  }

  & > .react-renderer {
    @apply my-12 first:mt-0 last:mb-0;
  }

  &.resize-cursor {
    @apply cursor-col-resize;
  }

  .ProseMirror-gapcursor {
    @apply relative w-full max-w-2xl mx-auto;

    &:after {
      @apply border-t-black/40 dark:border-t-white/40 w-full -top-[1.5em] max-w-2xl mx-auto left-0 right-0;
    }
  }
}

[data-theme='slash-command'] {
  width: 1000vw;
}

@import './partials/animations.css';
@import './partials/blocks.css';
@import './partials/code.css';
@import './partials/lists.css';
@import './partials/placeholder.css';
@import './partials/table.css';
@import './partials/typography.css';
@import './partials/details.css';
