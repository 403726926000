.ProseMirror .details {
  border: 1px solid #cbd5e0; /* Tailwind 'border-gray-400' */
  border-radius: 0.375rem; /* Tailwind 'rounded' */
  padding: 0.5rem; /* Tailwind 'p-2' */
  display: flex;
  gap: 0.25rem; /* Tailwind 'gap-0.25' */
  margin: 1.5rem 0; /* Tailwind 'my-6' */
}

.dark .ProseMirror .details {
  border-color: #4b5563; /* Tailwind 'dark:border-gray-600' */
}

.ProseMirror .details summary {
  font-weight: 700; /* Tailwind 'font-weight: bold' */
}

.ProseMirror .details summary::-webkit-details-marker {
  display: none; /* Safari 브라우저용 사용자 정의 스타일 */
}

.ProseMirror .details summary {
  list-style-type: none; /* 그 외의 브라우저용 사용자 정의 스타일 */
}

.ProseMirror .details > button {
  align-items: center;
  background: transparent;
  border-radius: 4px; /* Tailwind 'rounded' */
  display: flex;
  font-size: 0.625rem; /* Tailwind 'text-xs' */
  height: 1.25rem; /* Tailwind 'h-5' */
  justify-content: center;
  line-height: 1;
  margin-top: 0.1rem; /* Tailwind 'mt-0.25' */
  padding: 0;
  width: 1.25rem; /* Tailwind 'w-5' */
}

.ProseMirror .details > button:hover {
  background-color: #e5e7eb; /* Tailwind 'bg-gray-200' */
}

.dark .ProseMirror .details > button:hover {
  background-color: #1f2937; /* Tailwind 'dark:bg-gray-800' */
}

.ProseMirror .details > button::before {
  content: '\25B6';
}

.ProseMirror .details.is-open > button::before {
  transform: rotate(90deg);
}

.ProseMirror .details > div {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.ProseMirror .details > div > [data-type='detailsContent'] > :last-child {
  margin-bottom: 0.5rem;
}

.ProseMirror .details .details {
  margin: 0.5rem 0; /* Tailwind 'my-2' */
}

/* Tailwind css */

.ProseMirror {
  .details {
    @apply border border-gray-400 dark:border-gray-600 rounded p-2;
    display: flex;
    gap: 0.25rem;
    margin: 1.5rem 0;
    padding: 0.5rem;

    summary {
      font-weight: 700;
    }

    summary::-webkit-details-marker {
      /* Safari 브라우저용 사용자 정의 스타일 */
      display: none;
    }
    summary {
      list-style-type: none; /* 그 외의 브라우저용 사용자 정의 스타일 */
    }

    > button {
      align-items: center;
      background: transparent;
      border-radius: 4px;
      display: flex;
      font-size: 0.625rem;
      height: 1.25rem;
      justify-content: center;
      line-height: 1;
      margin-top: 0.1rem;
      padding: 0;
      width: 1.25rem;

      &:hover {
        @apply bg-gray-200 dark:bg-gray-800;
      }

      &::before {
        content: '\25B6';
      }
    }

    &.is-open > button::before {
      transform: rotate(90deg);
    }

    > div {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;

      > [data-type='detailsContent'] > :last-child {
        margin-bottom: 0.5rem;
      }
    }

    .details {
      margin: 0.5rem 0;
    }
  }
}
