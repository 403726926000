/* 공통 좌석 스타일 */

.seat {
  stroke: #0e1834;
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}

/* 팀별 일반 좌석 */
.ai-seat {
  fill: #fcf1c3;
}
.serviceDev-seat {
  fill: #f3eeff;
}
.regulatory-seat {
  fill: #fad7d0;
}
.platform-seat {
  fill: #fbdcc3;
}
.business2-seat {
  fill: #cbe4c1;
}
.business1-seat {
  fill: #d5ebf7;
}
.business-strategy-seat {
  fill: #dfe3f3;
}
.ceo-seat {
  fill: #f9d4d4;
}
.businessSupport-seat {
  fill: #ebfff4;
}
.financeTeam-seat {
  fill: #fff0eb;
}
.platform-dev-seat {
  fill: #fffee9;
}
.common-dev-seat {
  fill: #f3e6ff;
}
/* 팀별 TBD 좌석 */
.ai-tbd {
  fill: #fefae6;
}
.serviceDev-tbd {
  fill: #feefdd;
}
.business2-tbd {
  fill: #eff7ef;
}
.regulatory-tbd {
  fill: #fdede9;
}
.business1-tbd {
  fill: #edf7fd;
}
.business-strategy-tbd {
  fill: #f4f6fb;
}
.businessSupport-tbd {
  fill: #f8fffb;
}
.financeTeam-tbd {
  fill: #fffef5;
}
.platform-dev-tbd {
  fill: #fffef5;
}
.no-team-seat {
  fill: #f3e6ff;
}

/* 텍스트 스타일 */
.user-name {
  font-family: 'NanumSquare_acR', NanumSquare_ac;
  font-size: 21px;
  fill: #231815;
}

.role-text {
  fill: #797a7a;
  font-family: 'NanumSquare_acL', NanumSquare_ac;
  font-size: 14px;
}

.tbd-text {
  fill: #cccccc;
  font-family: 'NanumSquare_acR', NanumSquare_ac;
  font-size: 14px;
}

.team-title {
  font-family: NanumSquareEB, NanumSquare;
  font-size: 19px;
  fill: #231815;
}

/* 팀 클릭 가능 */
#dev1Team,
#dev2Team,
#serviceDevTeam,
#regulatoryTeam,
#AIDevTeam,
#business1Team,
#business2Team,
#ceo,
#seniorExecOffice,
#executiveOffice,
#businessSupportTeam,
#business2Team {
  cursor: pointer;
  pointer-events: all;
}
